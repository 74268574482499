import React, { FC, ReactNode } from "react";

export const Card: FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`bg- rounded-lg shadow-sm p-4 bg-[#f1f1ec] border-[1.5px] broder-slate-800 ${className}`}
    >
      {children}
    </div>
  );
};
