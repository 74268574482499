import React, { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";

export const Chip: FC<{
  label: string;
  className?: string;
  onClick?: () => void;
  onClose?: () => void;
}> = ({ label, className, onClose, onClick }) => {
  return (
    <span
      className={`inline-flex items-center px-2 py-1 rounded-lg border border-slate-300 bg-[#fafaf4] focus:outline-none focus:ring-2 focus:ring-blue-800 ${className} items-center justify-center text-center`}
      onClick={onClick}
    >
      <span className="text-sm">{label}</span>
      {onClose && (
        <AiOutlineClose className="text-red-500 ml-2" onClick={onClose} />
      )}
    </span>
  );
};
