import React, { FC } from "react";

export const Button: FC<{
  onClick: () => void;
  label: string;
  className?: string;
  disabled?: boolean;
}> = ({ onClick, className, label, disabled = false }) => {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 rounded-lg border border-slate-500 bg-[#596cad] text-slate-100 focus:outline-none focus:ring-2 focus:ring-blue-800 ${className}`}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
