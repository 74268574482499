import React from "react";
import Constants from "../constants";
import { Podcast } from "../models";

const useUser = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [podcastIsLoading, setPodcastIsLoading] = React.useState(false);
  const [podcast, setPodcast] = React.useState<Podcast | null>(null);

  const getPodcast = async (id: string) => {
    setPodcastIsLoading(true);
    try {
      const response = await fetch(
        `${Constants.API_ENDPOINT}user/get_podcast`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ podcast_id: id }),
        }
      );
      setPodcastIsLoading(false);
      if (response.status === 200) {
        const data = await response.json();
        setPodcast(data.podcast);
      }
      return null;
    } catch (error) {
      console.error("Failed to fetch:", error);
      setPodcastIsLoading(false);
      return null;
    }
  };

  const signUp = async (
    phoneNumber: string,
    interests: string[],
    publications: { name: string; preferenceLevel: number }[],
    referalCode?: string
  ) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${Constants.API_ENDPOINT}user/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone_number: phoneNumber,
          interests: interests,
          publications: publications,
          referal_code: referalCode,
        }),
      });
      setIsLoading(false);
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Failed to fetch:", error);
      setIsLoading(false);
      return false;
    }
  };
  return { signUp, isLoading, getPodcast, podcastIsLoading, podcast };
};

export default useUser;
