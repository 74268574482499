import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Landing } from "./pages/landing";
import { Playback } from "./pages/playback";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Refer from "./pages/refer.tsx";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/playback" element={<Playback />} />
        <Route path="/shareOrSubscribe" element={<Refer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
