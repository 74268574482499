import React, { FC, useState } from "react";

const RadioButtonGroup: FC<{
  options: string[];
  selected: string;
  onChange: (value: string) => void;
  className?: string;
  hideLabels?: boolean;
}> = ({ options, selected, onChange, className, hideLabels }) => {
  const [selectedValue, setSelectedValue] = useState(selected);
  return (
    <div className={`flex space-x-4 ${className} `}>
      {options.map((option) => (
        <label key={option} className="flex items-center">
          <input
            type="radio"
            value={option}
            checked={selectedValue == option}
            onChange={(e) => {
              setSelectedValue(e.target.value);
              onChange(e.target.value);
            }}
            className="form-radio h-4 w-4"
            // className=""
          />
          {!hideLabels && <span className="ml-2">{option}</span>}
        </label>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
