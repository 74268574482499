import React, { FC, useState, useEffect, useRef } from "react";
import { Podcast } from "../../models";
import logo from "../../wizer_logo.png";
import { useParams } from "react-router-dom";
import useUser from "../../useHooks/useUser";
import { Card } from "../../components/UICore/Card";
import { toast } from "react-toastify";
import Modal from "../../components/UICore/Modal";
import * as amplitude from "@amplitude/analytics-browser";
export const Playback: FC = () => {
  const showEditInterestsModal = useState(false);
  const podcastId = new URLSearchParams(window.location.search).get(
    "podcastId"
  );
  const { getPodcast, podcastIsLoading, podcast } = useUser();
  const [audioStartTime, setAudioStartTime] = useState(0);
  const [showTranscript, setShowTranscript] = useState(false);

  const onPlay = () => {
    console.log("Audio play started");
    if (audioStartTime !== 0) return; // don't want to double count
    setAudioStartTime(Date.now());
    amplitude.track("playback_started", { podcast_id: podcastId });
  };

  const onEnded = () => {
    console.log("Audio play ended");
    if (audioStartTime === 0) return;
    const duration = Date.now() - audioStartTime;
    console.log(`Audio play duration: ${duration}`);
    amplitude.track("playback_ended", {
      podcast_id: podcastId,
      duration: duration,
    });
  };

  const onPaused = () => {
    console.log("Audio play paused");
    if (audioStartTime === 0) return;
    const duration = Date.now() - audioStartTime;
    amplitude.track("playback_paused", {
      podcast_id: podcastId,
      duration: duration,
    });
  };

  useEffect(() => {
    if (podcastId) getPodcast(podcastId);
    else {
      getPodcast("65d1d2fd99930e16c8e4d2cd");
    }
  }, [podcastId]);

  return (
    <div className="min-h-screen flex flex-col items-center w-full bg-gradient-to-r from-[#f2f1e3] via-[#ffe8d6] to-[#f2f1e3]">
      <Modal
        onClose={() => {
          showEditInterestsModal[1](false);
        }}
        onConfirm={() => {
          showEditInterestsModal[1](false);
          window.location.href = "/";
        }}
        isOpen={showEditInterestsModal[0]}
        title="Edit Interests"
        onConfirmText="Resubscribe"
      >
        <div className="flex w-[300px] text-left">
          <p>To edit your interests please re-subscribe.</p>
        </div>
      </Modal>
      <div className="max-w-[500px] px-6 ">
        <div className="flex w-full  items-center justify-between pt-2 pb-6">
          <img
            src={logo}
            onClick={() => (window.location.href = "/")}
            alt="Echoverse Logo"
            className="w-auto max-w-xs h-auto max-h-14 cursor-pointer "
          />
          <a
            href="#"
            className="underline text-[#FF640C] text-sm"
            onClick={() => showEditInterestsModal[1](true)}
          >
            edit interests/publications
          </a>
        </div>
        {podcast === null || podcastIsLoading ? (
          <p className="text-center text-slate-800 text-lg font-bold">
            Loading...
          </p>
        ) : (
          <div>
            <p className="text-center text-slate-800 text-3xl font-semibold">
              {podcast.title}
            </p>
            <p className=" text-center text-slate-800">{podcast.date_string}</p>

            <p className="italic mb-2 text-center text-slate-500">
              {podcast.description}
            </p>
            <div className="space-y-3">
              <div className="flex flex-col">
                <h2 className="text-xl font-semibold mt-3 mb-4">Listen</h2>
                <Card>
                  <div className="flex justify-between items-center space-x-4">
                    <audio
                      controls
                      controlsList="nodownload noplaybackrate"
                      src={podcast.mp3_url}
                      className="w-full px-1"
                      onPlay={onPlay}
                      onEnded={onEnded}
                      onPause={onPaused}
                    ></audio>
                    <select
                      id="playbackRate"
                      defaultValue="1.25"
                      onChange={(e) => {
                        const audioPlayer = document.querySelector("audio");
                        if (audioPlayer) {
                          audioPlayer.playbackRate = parseFloat(e.target.value);
                        }
                      }}
                      className=" border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2 rounded-lg"
                    >
                      <option value="0.5">0.5x</option>
                      <option value="0.75">0.75x</option>
                      <option value="1">1x</option>
                      <option value="1.25">1.25x</option>
                      <option value="1.5">1.5x</option>
                      <option value="2">2x</option>
                    </select>
                  </div>

                  <p
                    className="p-2 underline cursor-pointer text-slate-500"
                    onClick={() => setShowTranscript(!showTranscript)}
                  >
                    {showTranscript ? "Hide" : "Show"} Transcript
                  </p>
                  {showTranscript && (
                    <div className="flex flex-col">
                      {podcast.transcript ? (
                        <p className="text-slate-500 text-md">
                          {podcast.transcript}
                        </p>
                      ) : (
                        <p className="text-slare-500 text-md">
                          Transcript not available
                        </p>
                      )}
                    </div>
                  )}
                </Card>
              </div>
              <div className="flex flex-col">
                <h2 className="text-xl font-semibold mb-1">Stories</h2>

                {podcast.story_titles.map((title, index) => {
                  const storyDomain = new URL(
                    podcast.story_urls[index]
                  ).hostname.replace("www.", "");
                  return (
                    <Card className="my-1">
                      <div key={title} className="flex flex-col">
                        <p>
                          {title}{" "}
                          <a
                            href={podcast.story_urls[index]}
                            target="_blank"
                            rel="noreferrer"
                            className="  underline"
                          >
                            ({storyDomain})
                          </a>
                        </p>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col mt-3">
              <h2 className="text-xl font-semibold mt-3 ">Share</h2>
              <div className="flex flex-col mt-3">
                <a
                  href={`sms:?&body=Check out this podcast on Echoverse: ${
                    podcast.title
                  } - ${podcast.description} - ${encodeURIComponent(
                    window.location.href
                  )}
                `}
                  target="_blank"
                  rel="noreferrer"
                  className="text-md text-indigo-500 underline mb-1"
                >
                  <Card className="my-1">Via iMessages</Card>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?text=Check out this podcast on Echoverse: ${podcast.title} - ${podcast.description} - ${podcast.mp3_url}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-md text-indigo-500 underline"
                >
                  <Card className="my-1">Via Twitter</Card>
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(`${window.location.href}`);
                    toast.success("Link copied to clipboard");
                  }}
                  className="text-md text-indigo-500 underline"
                >
                  <Card className="my-1">Copy Link</Card>
                </a>
              </div>
            </div>

            <footer className="mt-auto py-4  w-full text-sm ">
              <div className="text-center text-slate-600">
                <p>🌲 DDL Labs 2024</p>
                <p>daniel.longo@stanford.edu</p>
              </div>
            </footer>
          </div>
        )}
      </div>
    </div>
  );
};
