import React, { FC, useState, useEffect, KeyboardEvent } from "react";

export const Input: FC<{
  label?: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: () => void; // Optional onSubmit prop
  className?: string; // Optional className prop
}> = ({ label, type, placeholder, value, onChange, onSubmit, className }) => {
  const [formattedValue, setFormattedValue] = useState(value);

  useEffect(() => {
    if (type === "tel") {
      const numbers = value.replace(/[^\d]/g, "");
      const char: { [key: number]: string } = { 3: "-", 6: "-" };
      const phoneNumber = numbers.split("").reduce((acc, curr, idx) => {
        return acc + (char[idx] || "") + curr;
      }, "");
      setFormattedValue(phoneNumber);
    } else {
      setFormattedValue(value);
    }
  }, [value, type]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "tel") {
      const numbers = e.target.value.replace(/[^\d]/g, "");
      if (numbers.length <= 10) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  // Handle the key press event to check for Enter key
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onSubmit) {
      e.preventDefault(); // Prevent the default action to avoid any form submission if wrapped in a form
      onSubmit();
    }
  };

  return (
    <div className={`flex flex-col ${className}`}>
      {label && <label className="text-sm font-light">{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        value={formattedValue}
        onChange={handleChange}
        onKeyPress={handleKeyPress} // Add the key press event listener
        className="p-2 rounded-lg border bg-[#fefffe] border-slate-300 focus:outline-none focus:ring-2 focus:ring-blue-800"
      />
    </div>
  );
};
