import { FC } from "react";
import logo from "../../wizer_logo.png";
import { toast } from "react-toastify";
import { Card } from "../../components/UICore/Card";
const Refer: FC = () => {
  const referalCode = new URLSearchParams(window.location.search).get(
    "referalCode"
  );
  return (
    <div className="min-h-screen flex flex-col items-center w-full bg-gradient-to-r from-[#f2f1e3] via-[#ffe8d6] to-[#f2f1e3]">
      <div className="max-w-[500px] px-6 ">
        <div className="flex w-full  items-center justify-between pt-2">
          <img
            src={logo}
            onClick={() => (window.location.href = "/")}
            alt="Echoverse Logo"
            className="w-auto max-w-xs h-auto max-h-14 cursor-pointer "
          />
          <a href="#" className="underline text-[#FF640C] ">
            about
          </a>
        </div>
        <p className="text-5xl  text-slate-800 my-5 mb-4">
          <span className="text-[#FF640C]">Subscribe</span> or{" "}
          <span className="text-[#FF640C]">Share</span> for access
        </p>
        <h2 className="text-xl font-semibold my-2">Subscribe</h2>
        <a
          href="https://buy.stripe.com/4gw3dce92aR6dZmfZ0"
          target="_blank"
          rel="noreferrer"
          className="text-md text-indigo-500 underline"
        >
          <Card className="my-1">Subscribe $5 per month</Card>
        </a>
        <h2 className="text-xl font-semibold my-2">Share with a friend</h2>
        <a
          href={`sms:?&body=Personalized podcasts generated with AI: https://wizer.news/?referalCode=${referalCode}`}
          target="_blank"
          rel="noreferrer"
          className="text-md text-indigo-500 underline mb-1"
        >
          <Card className="my-1">Via iMessages</Card>
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(
              `https://wizer.news/?referalCode=${referalCode}`
            );
            toast.success("Link copied to clipboard");
          }}
          className="text-md text-indigo-500 underline"
        >
          <Card className="my-1">Copy Link</Card>
        </a>
      </div>
    </div>
  );
};

export default Refer;
