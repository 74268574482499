import React, { FC, useState } from "react";
import { Card } from "../../components/UICore/Card";
import { Input } from "../../components/UICore/Input";
import { Button } from "../../components/Button";
import { Chip } from "../../components/UICore/Chip";
import useUser from "../../useHooks/useUser";
import { Alert } from "../../components/UICore/Alert";
import { toast } from "react-toastify";
import logo from "../../wizer_logo.png";
import RadioButtonGroup from "../../components/UICore/RadioButtonGroup";
import * as amplitude from "@amplitude/analytics-browser";
const commonInterests = [
  "AI Startups",
  "Macro Economics",
  "Big Aquisitions",
  "Seed Stage",
  "Bitcoin",
  "Commodities",
  "B2B SaaS",
  "Electric Vehicles",
];

const commonPublications = [
  "Bloomberg",
  "The Information",
  "WSJ",
  "The Economist",
  "Stratechery",
];
export const Landing: FC = () => {
  const referalCode =
    new URLSearchParams(window.location.search).get("referalCode") || "";
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentTopic, setCurrentTopic] = useState("");
  const [currentNewPublication, setCurrentNewPublication] = useState("");
  const [publications, setPublications] = useState([
    { name: "Bloomberg", preferenceLevel: 2 },
    { name: "The Information", preferenceLevel: 2 },
    { name: "WSJ", preferenceLevel: 2 },
    { name: "The Economist", preferenceLevel: 2 },
    { name: "Stratechery", preferenceLevel: 2 },
  ] as { name: string; preferenceLevel: number }[]);

  const [topics, setTopics] = useState([
    "Startups",
    "AI",
    "Crypto",
    "Geopolitics",
  ] as string[]);

  const { signUp, isLoading } = useUser();

  return (
    <div className="min-h-screen flex flex-col items-center w-full bg-gradient-to-r from-[#f2f1e3] via-[#ffe8d6] to-[#f2f1e3]">
      <div className="max-w-[500px] px-6  ">
        <div className="flex w-full  items-center justify-between pt-2">
          <img
            src={logo}
            onClick={() => (window.location.href = "/")}
            alt="Echoverse Logo"
            className="w-auto max-w-xs h-auto max-h-14 cursor-pointer "
          />
          <a href="#" className="underline text-[#FF640C] ">
            about
          </a>
        </div>
        <p className="text-5xl  text-slate-800 my-5 mb-4">
          <span className="text-[#FF640C]">Personalized</span> AI Generated
          Podcast
        </p>
        {/* <p className="p-2">"My favorite way to start the day"- Elad Gil</p> */}
        <div className="space-y-4">
          <div className="flex flex-col">
            <h2 className="text-xl font-semibold my-2">Your Interests</h2>

            {topics.length === 0 && (
              <p className="text-sm text-slate-500 py-2 text-center ">
                Add some interests to get started
              </p>
            )}
            <div className="flex flex-wrap flex-row">
              {topics.map((topic) => (
                <Chip
                  label={topic}
                  key={topic}
                  className="mr-1 mt-1"
                  onClose={() => {
                    setTopics(topics.filter((t) => t !== topic));
                  }}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col">
            <p className="pb-2 text-slate-600 "> Add another</p>
            <div className="flex flex-row mb-2">
              <Input
                label=""
                className="p-0"
                type="text"
                placeholder="Any topic"
                value={currentTopic}
                onChange={(e) => setCurrentTopic(e.target.value)}
                onSubmit={() => {
                  if (currentTopic.length > 1) {
                    setTopics([...topics, currentTopic]);
                  }

                  setCurrentTopic("");
                }}
              />
              <Button
                label="Add"
                onClick={() => {
                  if (currentTopic.length > 1) {
                    setTopics([...topics, currentTopic]);
                  }

                  setCurrentTopic("");
                }}
                className="ml-2 bg-[#FF640C] text-white border-slate-100"
              />
            </div>
            <div className="flex flex-wrap flex-row">
              {commonInterests
                .filter((topic) => !topics.includes(topic))
                .map((topic) => (
                  <Chip
                    label={topic}
                    key={topic}
                    className="mr-1 mt-1"
                    onClick={() => {
                      setTopics([...topics, topic]);
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="flex flex-col">
            <h2 className="text-xl font-semibold my-3 mt-2">
              Your Favorite Publications
            </h2>
            <div className="flex flex-row items-center justify-between text-black ">
              <p className="font-semibold">Publication</p>
              <div className="flex space-x-[5px] justify-around flex-row">
                <span className="w-[50px] text-sm text-center">Exclude</span>
                <span className="w-[50px] text-sm text-center">Like</span>
                <span className="w-[50px] text-sm text-center">Love</span>
              </div>
            </div>
            {publications.map((pub) => (
              <div className="flex flex-row items-center justify-between">
                <div className="flex items-center">
                  <p className="text-slate-700">{pub.name}</p>
                  {!commonPublications.includes(pub.name) && (
                    <button
                      onClick={() =>
                        setPublications(
                          publications.filter((p) => p.name !== pub.name)
                        )
                      }
                      className="ml-2 text-red-500"
                    >
                      x
                    </button>
                  )}
                </div>
                <RadioButtonGroup
                  hideLabels
                  className="space-x-[5px] w-[165px] justify-around"
                  options={["1", "2", "3"]}
                  selected={pub.preferenceLevel.toString()}
                  onChange={(value) => {
                    setPublications(
                      publications.map((p) =>
                        p.name === pub.name
                          ? { name: p.name, preferenceLevel: +value }
                          : p
                      )
                    );
                  }}
                />
              </div>
            ))}
            <p className="text-slate-500 my-2">Add another</p>
            <div className="flex flex-row mb-2">
              <Input
                label=""
                type="text"
                placeholder="publication url"
                value={currentNewPublication}
                onChange={(e) => setCurrentNewPublication(e.target.value)}
                onSubmit={() => {
                  if (currentNewPublication.length > 1) {
                    setPublications([
                      ...publications,
                      { name: currentNewPublication, preferenceLevel: 3 },
                    ]);
                    setCurrentNewPublication("");
                  }
                }}
              />
              <Button
                label="Add"
                onClick={() => {
                  if (currentNewPublication.length > 1) {
                    setPublications([
                      ...publications,
                      { name: currentNewPublication, preferenceLevel: 1 },
                    ]);
                  }
                  setCurrentNewPublication("");
                }}
                className="ml-2 bg-[#FF640C] text-white border-slate-100"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <h2 className="text-xl font-semibold my-3 mt-2">Your Number</h2>

            <Input
              label=""
              type="tel"
              placeholder="(650)-555-5555"
              value={phoneNumber}
              className="mb-2"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col ">
          {isLoading ? (
            <Button
              label="Subscribing..."
              className="mt-6 bg-gradient-to-r px-14 py-4 from-indigo-500 to-rose-500 text-lg p-3 border-none rounded-xl text-white focus:outline-none focus:ring-2 focus:ring-blue-800 font-bold "
              onClick={() => {}}
              disabled
            />
          ) : (
            <Button
              label="Subscribe to Daily Episodes"
              className="mt-6 bg-gradient-to-r px-14 py-4 from-indigo-400 to-rose-500 text-lg p-3 border-none rounded-xl text-white focus:outline-none focus:ring-2 focus:ring-blue-800 font-bold "
              onClick={() => {
                signUp(phoneNumber, topics, publications, referalCode).then(
                  (success) => {
                    if (success) {
                      setPhoneNumber("");
                      setTopics([]);
                      toast.success(
                        "Please check for a confirmation text on how to activate your account"
                      );
                    } else {
                      toast.error("There was an error signing up");
                    }
                  }
                );
                amplitude.track("Subscribed", {});
              }}
            />
          )}
          <p className="text-sm text-center italic text-slate-700 mt-4 ">
            You may unsubscribe at any time. We will never share your phone
            number.
          </p>
        </div>
      </div>
      <footer className="mt-auto py-4  w-full text-sm ">
        <div className="text-center text-slate-600">
          <p>🌲 DDL Labs 2024</p>
          <p>daniel.longo@stanford.edu</p>
        </div>
      </footer>
    </div>
  );
};
